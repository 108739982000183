/* You can add global styles to this file, and also import other style files */

.bg-disabled {
    background-color: #e9ecef !important;
}

.text-wrap {
    word-wrap: break-word;
    word-break: break-all;
}

.table-rounded {
    font-size: 14px;
    border-collapse: collapse;
    border-spacing: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border-collapse: inherit;
    border: 1px solid #e9ecef;
}

.table-rounded td,
.table-rounded th {
    border: 1px solid #e9ecef;
    border-left: none;
    border-right: none;
    border-top: none;
}

.table-rounded tr:last-child td {
    border-bottom: none;
}

.fast-spin {
    -webkit-animation: fa-spin 1.2s infinite linear;
    animation: fa-spin 1.2s infinite linear;
}

.multi-line-text {
    white-space: pre-line;
    word-wrap: break-word;
    word-break: break-all;
}

.wrap {
    word-wrap: break-word;
    word-break: break-all;
}

.blue-bg {
    background-color: #337ab7;
    color: #fff;
}

.blue {
    color: #337ab7;
}

.blue-bg>hr {
    color: #fff;
    background-color: #fff;
}

.btn-primary {
    background-color: #337ab7;
    border-color: #337ab7;
}

.badge-primary {
    background-color: #337ab7;
}

.badge-normal-font {
    font-weight: 400;
}

.modal-header {
    background-color: #337ab7;
    color: #fff;
    padding: 10px;
    padding-left: 16px;
    padding-right: 16px;
}

.no-overflow-x {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.app-info-box {
    margin: 0 2%;
    flex: 1;
    overflow: hidden;
}

.nav-tabs--vertical {
    border-bottom: none;
    border-right: 1px solid #ddd;
    display: flex;
    flex-flow: column nowrap;
}

.nav-tabs--left {
    margin: 0 15px;
}

.nav-tabs--left .nav-item+.nav-item {
    margin-top: .25rem;
}

.nav-tabs--left .nav-link {
    transition: border-color .125s ease-in;
    white-space: nowrap;
    padding-right: 8px;
}

.nav-tabs--left .nav-link:hover {
    background-color: #f7f7f7;
    border-color: transparent;
}

.nav-tabs--left .nav-link.active {
    border-bottom-color: #ddd;
    border-right-color: #fff;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    margin-right: -1px;
}

.nav-tabs--left .nav-link.active:hover {
    background-color: #fff;
    border-color: #0275d8 #fff #0275d8 #0275d8;
}

hr.style3 {
    border-top: 1px dashed #e9e9e9;
}

.text-indent {
    text-indent: -1.28571429em;
    padding-left: 1.28571429em;
}

.ml-10 {
    margin-left: 6rem!important;
}

.mr-10 {
    margin-right: 6rem!important;
}

.pl-10 {
    padding-left: 6rem!important;
}

.pr-10 {
    padding-right: 6rem!important;
}

input {
    /* Avoid use typing under the clear button. */
    padding-right: 30px !important;
}

.input-group-btn {
    position: relative;
    /* This avoid the "clear" button being hidden while 
     the input has focus on. */
    z-index: 1000;
}

.input-group-btn a.btn {
    position: absolute;
    right: 0px;
    top: 0px;
}


/* This avoid the bad effect when clicking the button. */

.input-group-btn a.btn:hover,
.input-group-btn a.btn:active {
    box-shadow: none;
}

.badge-pink {
    color: white;
    background-color: #d63384;
}

.abc-checkbox label::before {
    top: 4px;
}

.abc-checkbox label::after {
    top: 4px;
}

.abc-checkbox input[type="checkbox"]:disabled+label,
.abc-checkbox input[type="radio"]:disabled+label {
    opacity: 1.0;
}

.abc-checkbox input[type="checkbox"]:disabled+label::before,
.abc-checkbox input[type="radio"]:disabled+label::before {
    /* background-color: #e9ecef; */
    cursor: default;
}

.abc-checkbox input[type="checkbox"]:disabled+label::after,
.abc-checkbox input[type="radio"]:disabled+label::after {
    cursor: default;
}

.user-profile-popover {
    width: 400px;
}